import "./main.css";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import background from "./img/pulse-bg.png";
import logo from "./img/pulse-synthesis.png";

let app = Elm.Main.init({
  node: document.getElementById("root")
});

app.ports.storeToken.subscribe(function(token){
  try{
    localStorage.setItem('token',token);
    return app.ports.tokenSaved.send(null);
  } catch(e){
    return app.ports.tokenNotSaved.send(null);
  }
});

app.ports.requestToken.subscribe(function(tokenName){
  try{
    const token = localStorage.getItem(JSON.parse(tokenName));
    //app.ports.receiveNoToken.send('');
    token? app.ports.receiveToken.send(token) : app.ports.receiveNoToken.send(null);
  }catch(e){
    app.ports.receiveNoToken.send(null);
  }
});
// Elm.embed(
//   document.getElementById('root'),
//   logo,
//   background // Pass image path as a flag for Html.programWithFlags
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
